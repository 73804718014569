import { LOAN_PURPOSES } from '../../../../localizations/config/NO/sambla/loan-purposes';
import EMPLOYMENT_TYPES from '../../../../localizations/config/NO/sambla/employment-types';
import HOME_TYPES from '../../../../localizations/config/NO/sambla/home-types';
import MARITAL_STATUS from '../../../../localizations/config/NO/sambla/marital-status';
import DEPENDANTS from '../../../../localizations/config/NO/sambla/dependants';
import EDUCATION_TYPES from '../../../../localizations/config/NO/sambla/education-types';

import {
  email,
  mergeLoan,
  dependants,
  purpose,
  homeType,
  civilStatus,
  homeCost,
  employmentType,
  employerName,
} from '../../../../validators';

import validateMoveInDate from '../../../../validators/FI/validateMoveInDate';
import { validateCurrentLoans, validateMonthlyCostCurrentLoans } from '../../../../validators/NO/currentLoans';
import monthlySalaryAfterTax from '../../../../validators/NO/monthlySalaryAfterTax';
import { validateAnnualSalaryBeforeTax, spouseAnnualIncomeBeforeTaxValidator } from '../../../../validators/NO/annualSalaryBeforeTax';
import otherIncomeAfterTax from '../../../../validators/otherIncomeAfterTax';
import { validateEmploymentSinceYear, validateEmploymentSinceMonth } from '../../../../validators/employedSince';
import phoneNumber from '../../../../validators/NO/phoneNumber';
import { validateEmploymentToYear, validateEmploymentToMonth } from '../../../../validators/employedTo';
import { validateRequiredButtonGroup } from '../../../../validators/requiredButtonGroup';
import validateNationalId from '../../../../validators/NO/nationalId';
import validateRentalIncome from '../../../../validators/NO/rentalIncome';

import textResource from '../../../../utils/text-resources/sambla/NO/messages';
import submit from '../../../../mappers/NO/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreementEng: 'https://www.sambla.no/en/user-agreement/',
    dataPolicyEng: 'https://www.sambla.no/en/privacy-policy/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'TotalAmountInput',
          props: {
            name: 'totalAmount',
            placeholder: textResource.applicationLoanAmountPlaceholder,
            label: textResource.applicationLoanAmountLabel,
            suffix: textResource.applicationLoanAmountSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.applicationLoanAmountTooltip),
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateMonthlyCostDesktop: textResource.monthlyCostApproximateCostPerMonthDesktop,
            approximateMonthlyCostMobile: textResource.monthlyCostApproximateCostPerMonthMobile,
            approximateMonthlyCostYears: textResource.monthlyCostApproximateCostLabelYears,
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: false,
            visibleWhen: 'return(formState.values.repaymentTime > 5)',
            margin: false,
            special: true,
            center: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            name: 'merge',
            label: textResource.currentLoanMergeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.currentLoanMergeTooltip),
          },
        },
        {
          component: 'ConsolidationLoanAmountInput',
          props: {
            name: 'consolidationAmount',
            consolidationLabel: textResource.combinedLoanConsolidationAmount,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.merge === true);',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            validate: email,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            validate: phoneNumber,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.phoneNumberPlaceholder,
            tooltip: tooltip(textResource.phoneNumberTooltip),
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.samblaPlusText,
            linkText: textResource.samblaPlusReadMore,
            links: {
              SV: 'https://www.sambla.se/sambla-plus/',
              NO: 'https://www.sambla.no/sambla-plus/',
              EN: 'https://www.sambla.no/en/sambla-plus/',
              FI: 'https://www.sambla.fi/sambla-plus/',
            },
          },
        },
        {
          component: 'UserAgreementDataPolicy',
          props: {
            lead: true,
            userAgreement: 'https://www.sambla.no/om-sambla/brukeravtale/',
            dataPolicy: 'https://www.sambla.no/om-sambla/personvernerklaering/',
            dataPolicyEng: 'https://www.sambla.no/en/privacy-policy/',
            userAgreementEng: 'https://www.sambla.no/en/user-agreement/',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 1,
            sendAbandonCartEvent: true,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
          },
        },
        {
          component: 'Disclaimer',
          props: {
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormBottom',
          props: {
            label: textResource.safeAndSecure,
            trustPilotData: {
              locale: 'no-NO',
              buData: '5818b0250000ff000596e1d5',
            },
          },
        },
      ],
    },
    {
      title: textResource.slideTitleOne,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'civilStatus',
            label: textResource.civilStatusLabel,
            hasValidateFunction: true,
            validate: civilStatus,
            buttons: MARITAL_STATUS,
            tooltip: tooltip(textResource.civilStatusTooltip),
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'dependants',
            label: textResource.dependantsLabel,
            hasValidateFunction: true,
            buttonLayout: 'compact',
            validate: dependants,
            buttons: DEPENDANTS,
            tooltip: tooltip(textResource.dependantsTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'educationLevel',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.educationLabel,
            placeholder: textResource.educationLabel,
            buttons: EDUCATION_TYPES,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'nationalId',
            label: textResource.nationalIdLabel,
            validate: validateNationalId,
            placeholder: textResource.nationalIdPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.nationalIdTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            name: 'purpose',
            label: textResource.purposeHeaderText,
            dynamicLabel: [
              {
                type: 'calculation',
                visibleWhen: 'return (formState.values.merge === false || (formState.values.totalAmount - formState.values.consolidationAmount > 5000));',
                originalLabel: textResource.purposeHeaderText,
                values: [
                  {
                    label: textResource.newPurposeHeaderText,
                  },
                ],
              },
            ],
            placeholder: textResource.purposeHeaderText,
            hasValidateFunction: true,
            validate: purpose,
            visibleWhen: 'return (formState.values.merge === false || parseInt(formState.values.totalAmount) > parseInt(formState.values.consolidationAmount));',
            buttons: LOAN_PURPOSES,
            tooltip: tooltip(textResource.purposeTooltip),
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 2,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
          },
        },
      ],
    },
    {
      title: textResource.slideTitleTwo,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'employmentType',
            label: textResource.employmentTypeLabel,
            placeholder: textResource.employmentTypeLabel,
            hasValidateFunction: true,
            validate: employmentType,
            buttons: EMPLOYMENT_TYPES,
            tooltip: tooltip(textResource.employmentTypeTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'employmentSince',
            label: textResource.employmentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceYearDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'applicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employmentSinceYearPlaceholder,
            validate: validateEmploymentSinceYear,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.employmentSinceYearTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            label: textResource.employmentSinceMonthLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employedSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.applicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.employmentSinceMonthTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employerName',
            validate: employerName,
            hasValidateFunction: true,
            label: textResource.employerNameLabel,
            placeholder: textResource.employerNamePlaceholder,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employerNameLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedEmployerNameDynamicLabel,
                  },
                ],
              },
            ],
            tooltip: tooltip(textResource.employerNameTooltip),
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed" && formState.values.applicant.employmentType !== "rehabilitation") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'annualIncomeBeforeTax',
            isNumber: true,
            label: textResource.annualIncomeLabel,
            placeholder: textResource.monthlySalaryPlaceholder,
            validate: validateAnnualSalaryBeforeTax,
            tooltip: tooltip(textResource.annualIncomeBeforeTaxTooltip),
            hasValidateFunction: true,
            type: 'tel',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            label: textResource.monthlyIncomeAfterTaxLabel,
            placeholder: textResource.monthlyIncomeAfterTaxPlaceholder,
            validate: monthlySalaryAfterTax,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.monthlyIncomeAfterTaxTooltip),
            type: 'tel',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'otherIncome',
            label: textResource.otherIncomeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.otherIncomeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            isNumber: true,
            name: 'otherMonthlyIncomeAfterTax',
            placeholder: textResource.netOtherIncomePlaceholder,
            label: textResource.netOtherIncomeLabel,
            type: 'tel',
            visibleWhen: 'return (formState.values.applicant.otherIncome !== undefined && formState.values.applicant.otherIncome === true);',
            validate: otherIncomeAfterTax,
            hasValidateFunction: true,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'spouseAnnualIncomeBeforeTax',
            validate: spouseAnnualIncomeBeforeTaxValidator,
            isNumber: true,
            hasValidateFunction: true,
            label: textResource.spouseAnnualIncomeLabel,
            placeholder: textResource.monthlySalaryPlaceholder,
            type: 'tel',
            visibleWhen: 'return (formState.values.applicant.civilStatus && (formState.values.applicant.civilStatus === "married" || formState.values.applicant.civilStatus === "partner"));',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'hasRentalIncome',
            label: textResource.hasRentalIncomeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.hasRentalIncomeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'rentalMonthlyIncomeBeforeTax',
            isNumber: true,
            label: textResource.rentalMonthlyIncomeBeforeTaxLabel,
            placeholder: textResource.rentalMonthlyIncomeBeforeTaxPlaceholder,
            type: 'tel',
            visibleWhen: 'return (formState.values.applicant.hasRentalIncome);',
            validate: validateRentalIncome,
            hasValidateFunction: true,
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 3,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
          },
        },
      ],
    },
    {
      title: textResource.slideTitleThree,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'homeType',
            label: textResource.homeTypeLabel,
            hasValidateFunction: true,
            validate: homeType,
            buttons: HOME_TYPES,
            tooltip: tooltip(textResource.homeTypeTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabel,
            placeholder: textResource.moveInDatePlaceholder,
            validate: validateMoveInDate,
            hasValidateFunction: true,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            label: textResource.homeCostLabel,
            validate: homeCost,
            placeholder: textResource.homeCostPlaceholder,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.homeCostTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant.currentLoans.houseLoan',
          props: {
            isNumber: true,
            name: 'amount',
            placeholder: textResource.houseLoansAmountPlaceholder,
            label: textResource.houseLoansAmountLabel,
            type: 'tel',
            validate: validateCurrentLoans,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.houseLoanAmountTooltip),
            visibleWhen: 'return (formState.values.applicant.homeType === "villa" || formState.values.applicant.homeType === "condominium" || formState.values.applicant.homeType === "ownedApartment");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant.currentLoans.houseLoan',
          props: {
            isNumber: true,
            name: 'monthlyCost',
            tooltip: tooltip(textResource.houseLoanMonthlyCostTooltip),
            label: textResource.houseLoansMonthlyCostLabel,
            placeholder: textResource.houseLoansMonthlyCostPlaceholder,
            type: 'tel',
            validate: validateMonthlyCostCurrentLoans,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.applicant.homeType === "villa" || formState.values.applicant.homeType === "condominium" || formState.values.applicant.homeType === "ownedApartment");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'houseLoanPart',
            label: textResource.houseLoansPartLabel,
            hasValidateFunction: true,
            buttonLayout: 'compact',
            visibleWhen: 'return (formState.values.applicant.homeType === "villa" || formState.values.applicant.homeType === "condominium" || formState.values.applicant.homeType === "ownedApartment");',
            buttons: [
              {
                label: textResource.houseLoansLoanIsOwnedByApplicant,
                value: '100',
              },
              {
                label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                value: '50',
              },
            ],
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 4,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
          },
        },
      ],
    },
    {
      title: textResource.slideTitleFour,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.addCoApplicantLabel,
            tooltip: tooltip(textResource.addCoApplicantTooltip),
            transparent: true,
            header: true,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            label: textResource.addCoApplicantDesc,
            name: 'hasCoApplicant',
            rightPadding: true,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            tooltip: tooltip(textResource.addCoApplicantTooltip),
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            name: 'textbox2',
            text: textResource.currentLoansInformation,
            transparent: true,
            header: true,
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'hasCarLoan',
            label: textResource.carLoansLabel,
            tooltip: tooltip(textResource.carLoanTooltip),
            buttonLayout: 'toggle',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant.currentLoans.car',
          props: {
            isNumber: true,
            name: 'amount',
            placeholder: textResource.carLoansAmountPlaceholder,
            tooltip: tooltip(textResource.carLoanAmountTooltip),
            label: textResource.carLoansAmountLabel,
            type: 'tel',
            validate: validateCurrentLoans,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.applicant.hasCarLoan === true);',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant.currentLoans.car',
          props: {
            isNumber: true,
            name: 'monthlyCost',
            tooltip: tooltip(textResource.carLoansMonthlyCostTooltip),
            placeholder: textResource.carLoansMonthlyCostPlaceholder,
            label: textResource.carLoansMonthlyCostLabel,
            type: 'tel',
            validate: validateMonthlyCostCurrentLoans,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.applicant.hasCarLoan === true);',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'carLoanPart',
            label: textResource.carLoansPartLabel,
            hasValidateFunction: true,
            buttonLayout: 'compact',
            visibleWhen: 'return (formState.values.applicant.hasCarLoan === true);',
            buttons: [
              {
                label: textResource.carLoansLoanIsOwnedByApplicant,
                value: '100',
              },
              {
                label: textResource.carLoansLoanIsSharedWithCoApplicant,
                value: '50',
              },
            ],
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'hasStudentLoan',
            label: textResource.studentLoansLabel,
            tooltip: tooltip(textResource.studentLoanTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            buttonLayout: 'toggle',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant.currentLoans.studentLoan',
          props: {
            isNumber: true,
            name: 'amount',
            tooltip: tooltip(textResource.studentLoanAmountTooltip),
            label: textResource.studentLoansAmountLabel,
            placeholder: textResource.studentLoansAmountPlaceholder,
            type: 'tel',
            validate: validateCurrentLoans,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.applicant.hasStudentLoan === true);',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant.currentLoans.studentLoan',
          props: {
            isNumber: true,
            name: 'monthlyCost',
            tooltip: tooltip(textResource.studentLoanMonthlyCostTooltip),
            label: textResource.studentLoansMonthlyCostLabel,
            placeholder: textResource.studentLoansMonthlyCostPlaceholder,
            type: 'tel',
            validate: validateMonthlyCostCurrentLoans,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.applicant.hasStudentLoan === true);',
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 5,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
            visibleWhen: 'return (formState.values.hasCoApplicant === true);',
          },
        },
        {
          component: 'SubmitButton',
          sectionName: 'applicant',
          props: {
            label: textResource.formSubmit,
            ctaBtnStyle: true,
            messages: {
              uncaughtSubmitError: textResource.submitErrorUncaught,
              generalSubmitError: textResource.submitErrorGeneral,
            },
            visibleWhen: 'return (formState.values.hasCoApplicant !== true);',
          },
        },
      ],
    },
    {
      title: textResource.slideTitleFive,
      fullview: true,
      coApplicantSlide: true,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'nationalId',
            label: textResource.coApplicantNationalIdLabel,
            placeholder: textResource.coApplicantNationalIdPlaceholder,
            validate: validateNationalId,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.coApplicantNationalIdTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'phoneNumber',
            label: textResource.coApplicantPhoneNumberLabel,
            placeholder: textResource.coApplicantPhoneNumberPlaceholder,
            validate: phoneNumber,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantPhoneNumberTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'coApplicant',
          props: {
            name: 'email',
            label: textResource.coApplicantEmailLabel,
            placeholder: textResource.coApplicantEmailPlaceholder,
            validate: email,
            tooltip: tooltip(textResource.coApplicantEmailTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'civilStatus',
            label: textResource.coApplicantCivilStatusLabel,
            hasValidateFunction: true,
            validate: civilStatus,
            buttons: MARITAL_STATUS,
            tooltip: tooltip(textResource.coApplicantCivilStatusTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'dependants',
            label: textResource.coApplicantDependantsLabel,
            hasValidateFunction: true,
            validate: dependants,
            buttons: DEPENDANTS,
            tooltip: tooltip(textResource.coApplicantDependantsTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'educationLevel',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.coApplicantEducationLabel,
            placeholder: textResource.coApplicantEducationLabel,
            buttons: EDUCATION_TYPES,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentType',
            label: textResource.coApplicantEmploymentTypeLabel,
            hasValidateFunction: true,
            validate: employmentType,
            buttons: EMPLOYMENT_TYPES,
            tooltip: tooltip(textResource.coApplicantEmploymentTypeTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSince',
            validate: validateEmploymentSinceYear,
            label: textResource.coApplicantEmploymentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'coApplicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.coApplicantEmploymentSinceYearPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.coApplicantEmploymentSinceYearTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceMonthDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceMonthDynamicLabel,
                  },
                ],
              },
            ],
            label: textResource.coApplicantEmploymentSinceMonthLabel,
            placeholder: textResource.coApplicantEmploymentSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.coApplicantEmploymentSinceMonthTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentTo',
            label: textResource.coApplicantEmployedToYearLabel,
            validate: validateEmploymentToYear,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.coApplicantEmployedToYearPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployedToTooltip),
            visibleWhen: 'return (formState.values.coApplicant.employmentType === "parentalLeave");',
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.coApplicantEmployedToMonthLabel,
            validate: validateEmploymentToMonth,
            hasValidateFunction: true,
            placeholder: textResource.employedToMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentType === "parentalLeave");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employerName',
            validate: employerName,
            hasValidateFunction: true,
            sectionName: 'coApplicant',
            label: textResource.coApplicantEmployerNameLabel,
            placeholder: textResource.coApplicantEmployerNamePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployerNameTooltip),
            visibleWhen: 'return (formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed" && formState.values.coApplicant.employmentType !== "rehabilitation") );',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'annualIncomeBeforeTax',
            isNumber: true,
            label: textResource.coApplicantAnnualIncomeBeforeTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeBeforeTaxPlaceholder,
            validate: validateAnnualSalaryBeforeTax,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantAnnualIncomeBeforeTaxTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            label: textResource.coApplicantMonthlyIncomeAfterTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeAfterTaxPlaceholder,
            validate: monthlySalaryAfterTax,
            tooltip: tooltip(textResource.coApplicantMonthlyIncomeAfterTaxTooltip),
            hasValidateFunction: true,
            type: 'tel',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'otherIncome',
            label: textResource.coApplicantOtherIncomeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.coApplicantOtherIncomeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            isNumber: true,
            name: 'otherMonthlyIncomeAfterTax',
            placeholder: textResource.coApplicantNetOtherIncomePlaceholder,
            validate: otherIncomeAfterTax,
            hasValidateFunction: true,
            label: textResource.coApplicantNetOtherIncomeLabel,
            type: 'tel',
            visibleWhen: 'return (formState.values.coApplicant.otherIncome !== undefined && formState.values.coApplicant.otherIncome === true);',
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'homeType',
            label: textResource.coApplicantHomeTypeLabel,
            hasValidateFunction: true,
            validate: homeType,
            tooltip: tooltip(textResource.coApplicantHomeTypeTooltip),
            buttons: HOME_TYPES,
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabelCoApplicant,
            placeholder: textResource.moveInDatePlaceholderCoApplicant,
            validate: validateMoveInDate,
            hasValidateFunction: true,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            label: textResource.coApplicantHomeCostLabel,
            placeholder: textResource.coApplicantHomeCostPlaceholder,
            validate: homeCost,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantHomeCostTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'hasRentalIncome',
            label: textResource.coApplicantHasRentalIncomeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.coApplicantHasRentalIncomeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'rentalMonthlyIncomeBeforeTax',
            isNumber: true,
            label: textResource.coApplicantRentalMonthlyIncomeBeforeTaxLabel,
            placeholder: textResource.coApplicantRentalMonthlyIncomeBeforeTaxPlaceholder,
            type: 'tel',
            visibleWhen: 'return (formState.values.coApplicant.hasRentalIncome);',
            validate: validateRentalIncome,
            hasValidateFunction: true,
          },
        },
        {
          component: 'SubmitButton',
          sectionName: 'coApplicant',
          props: {
            label: textResource.formSubmit,
            ctaBtnStyle: true,
            messages: {
              uncaughtSubmitError: textResource.submitErrorUncaught,
              generalSubmitError: textResource.submitErrorGeneral,
            },
          },
        },
      ],
    },
  ],
};

export default data;
